import { Link, navigate } from "gatsby"

export const clampedRandom = (max = 0.1) => Math.min(Math.random(), max)

export const truncate = (input: string, length = 5) =>
  input.length > length ? `${input.substring(0, length)}...` : input

export const get = (obj: object, path: string, defaultValue?: string) => {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      )
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
  return result === undefined || result === obj ? defaultValue : result
}

export function chunk(array: any[], size: number) {
  const chunked_arr = []
  let copied = [...array] // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / size) // Round up to the nearest integer
  for (let i = 0; i < numOfChild; i++) {
    chunked_arr.push(copied.splice(0, size))
  }
  return chunked_arr
}

export function stripTrailingSlash(str: string) {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1)
  }
  return str
}

export function stripLeadingSlash(str: string) {
  if (str[0] === "/") {
    return str.substr(1, str.length)
  }
  return str
}

// Reference: from job post template
export const StatefulNav: React.FC<{ previous: any; next: any }> = ({
  previous,
  next,
}) => {
  return (
    <nav className="w-full px-10 py-8">
      <ul className="flex justify-between m-0 list-none">
        <li>
          {previous && (
            <Link
              to={previous.fields.slug}
              rel="prev"
              className="p-6 text-gray-700 bg-gray-100 hover:bg-vp-lightgray hover:text-vp-blue"
            >
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link
              to={next.fields.slug}
              rel="next"
              className="p-6 text-gray-700 bg-gray-100 hover:bg-vp-lightgray hover:text-vp-blue"
            >
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
    </nav>
  )
}

export const navigateToPrevOrDefault = (defaultUrl = "/about") => {
  if (window) {
    window.history.back()
  } else {
    navigate(defaultUrl)
  }
}

export const isSSR = typeof window === "undefined"

export const isMobileBrowser = {
  Android: function() {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: function() {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    )
  },
  any: function() {
    try {
      return (
        isMobileBrowser.Android() ||
        isMobileBrowser.BlackBerry() ||
        isMobileBrowser.iOS() ||
        isMobileBrowser.Opera() ||
        isMobileBrowser.Windows()
      )
    } catch {
      return false
    }
  },
}
