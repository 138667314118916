import React, { useState, ReactElement } from "react"
import * as Sentry from "@sentry/browser"

import Login from "./components/Login"
import { stripLeadingSlash, stripTrailingSlash } from "./utils"

export enum Languages {
  EN = "EN",
  JP = "JP",
}

const INITIAL_LANGUAGE = Languages.EN

Sentry.init({
  dsn: "https://2c8b1ee8a1124d1f8cd6940e1f7f1c6a@sentry.io/2295688",
})

export const LangContext = React.createContext({
  lang: INITIAL_LANGUAGE,
  setLang: (lang: Languages) => {},
})

const EditModeWrapper: React.FC = ({ children }) => {
  return (
    <>
      <div className="fixed top-0 left-0 z-50 flex flex-col items-center justify-center w-full pointer-events-none">
        <div className="py-2 px-4 bg-red-600 flex gap-2 items-end rounded-b-md font-sans text-sm">
          <span className="text-gray-100 leading-3">Edit Mode.</span>
          <span className=" text-gray-100 leading-3">
            Please save your changes on every page, otherwise it will not show
            up on the live site.
          </span>
        </div>
      </div>
      {children}
    </>
  )
}

interface ProviderProps {
  children: ReactElement
}

const getLanguagesFromPathname = (pathname: string) => {
  const lang = stripTrailingSlash(stripLeadingSlash(pathname))
  switch (lang) {
    case "en":
      return Languages.EN
    case "jp":
      return Languages.JP
    default:
      return INITIAL_LANGUAGE
  }
}

const Provider: React.FC<ProviderProps> = ({ children }) => {
  const [language, setLanguage] = useState<Languages>(
    getLanguagesFromPathname(window.location.pathname)
  )
  const [authenticated, setAuthenticated] = useState(false)

  if (process.env.NODE_ENV !== "production") {
    if (!authenticated) {
      // tina enabled site needs authentication in non-production settings (e.g. gatsby cloud)
      const authenticate = () => setAuthenticated(true)
      return (
        <Login
          onAuth={authenticate}
          flavorText="Welcome to Vantage Point Editor"
        />
      )
    } else {
      // other env
      return (
        <EditModeWrapper>
          <LangContext.Provider
            value={{
              setLang: setLanguage,
              lang: language,
            }}
          >
            {children}
          </LangContext.Provider>
        </EditModeWrapper>
      )
    }
  }

  // prod
  return (
    <LangContext.Provider
      value={{
        setLang: setLanguage,
        lang: language,
      }}
    >
      {children}
    </LangContext.Provider>
  )
}

interface WrapperProps {
  element: ReactElement
}

const Wrapper: React.FC<WrapperProps> = ({ element }) => (
  <Provider>{element}</Provider>
)

export default Wrapper
