module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MCR9HRJC","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-tinacms/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-tinacms-remark","id":"4e049a88-2f70-5135-883f-87a76d01585d","name":"gatsby-tinacms-remark","version":"0.7.13","pluginOptions":{"plugins":[]},"nodeAPIs":["setFieldsOnGraphQLNodeType"],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-tinacms-git","id":"340cd029-d23f-515e-b82a-a593e0affa7d","name":"gatsby-tinacms-git","version":"0.4.7","pluginOptions":{"plugins":[],"gitRemote":"git@github.com:panzerstadt-dev/the-initiative-vp-web.git","defaultCommitMessage":"Edited with TinaCMS on Gatsby Cloud","defaultCommitName":"vp-editor-bot","defaultCommitEmail":"dashboard.vpointcareers@gmail.com","pushOnCommit":true},"nodeAPIs":["onCreateDevServer"],"browserAPIs":["onClientEntry"],"ssrAPIs":[]}],"sidebar":{"hidden":true,"position":"displace"}},
    },{
      plugin: require('../node_modules/gatsby-tinacms-git/gatsby-browser.js'),
      options: {"plugins":[],"gitRemote":"git@github.com:panzerstadt-dev/the-initiative-vp-web.git","defaultCommitMessage":"Edited with TinaCMS on Gatsby Cloud","defaultCommitName":"vp-editor-bot","defaultCommitEmail":"dashboard.vpointcareers@gmail.com","pushOnCommit":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VantagePoint","short_name":"VP","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/vp-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":880,"withWebp":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
